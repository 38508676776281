<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-row>
          <b-col>
            <b-card>
              <b-card-header
                class="justify-content-start p-0"
                :class="{'pb-1 mb-1 border-bottom': participants.length > 1}"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.back()"
                >
                  <feather-icon
                    class="mr-25"
                    icon="ChevronLeftIcon"
                  />
                </b-button>
                <h3 class="mb-0 ml-2">
                  {{ title }}
                </h3>
              </b-card-header>
              <b-row
                v-if="participants.length > 1"
              >
                <b-col
                  cols="12"
                >
                  <b-form-group
                    :label-class="`h5`"
                    label="Select Participant"
                  >
                    <v-select
                      id="selectParticipant"
                      v-model="selectOrg"
                      :get-option-label="option => option.programs_applicantstable.users_associationtable.users_customuser.full_name"
                      :options="participants"
                      :reduce="participant => participant.id"
                      placeholder="Choose one from the list"
                      @input="$router.replace({name: $route.name,
                                               params: {pid: $route.params.pid,
                                                        apid: $route.params.apid,
                                                        aid: selectOrg}})"
                    />
                  </b-form-group>
                  <hr>
                </b-col>
              </b-row>
            </b-card>
            <form-wizard
              color="#7367F0"
              :title="null"
              :subtitle="null"
              shape="square"
              finish-button-text="Submit"
              back-button-text="Previous"
              :hide-buttons="$route.name === 'startup-review-questions' && !selectOrg"
              class="mb-3"
              @on-complete="updateAnswer"
            >
              <tab-content
                v-for="(section, i) in questions"
                :key="i"
                :title="i"
              >
                <b-row
                  v-for="(question, index) in section"
                  :key="index"
                >
                  <b-col>
                    <b-form-group
                      :label="`Q. ${question.question}`"
                      :label-class="`h5`"
                      :label-for="`question-${index}`"
                      class="mb-2"
                    >
                      <b-input-group class="mt-50">
                        <b-form-textarea
                          v-if="question.input_type === 'Long text'"
                          :id="`answer-${index}`"
                          v-model="question.programs_startupresponsetables[0].answer"
                          :readonly="readonly"
                          placeholder="Your answer"
                          required
                        />
                        <v-select
                          v-else-if="question.input_type === 'Dropdown' || question.input_type === 'Multiselect'"
                          :id="`answer-${index}`"
                          v-model="question.programs_startupresponsetables[0].answer"
                          :disabled="readonly"
                          :options="JSON.parse(question.options)"
                          append-to-body
                          :multiple="question.input_type === 'Multiselect'"
                          label="Choose from the following"
                          placeholder="Select from list"
                          class="w-100"
                        />
                        <b-form-input
                          v-else
                          :id="`answer-${index}`"
                          v-model="question.programs_startupresponsetables[0].answer"
                          :readonly="readonly"
                          placeholder="Your answer"
                          required
                          :type="question.input_type === 'Number' ? 'number' : 'text'"
                        />
                        <b-input-group-append
                          v-if="readonly === true && question.input_type==='Document'"
                        >
                          <b-button
                            v-if="question.programs_startupresponsetables[0].answer"
                            variant="outline-warning"
                            target="_blank"
                            :href="prependHttps(question.programs_startupresponsetables[0].answer)"
                          >View Document
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </tab-content>
            </form-wizard>
          </b-col>
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getUserData } from '@/auth/utils'
import { FormWizard, TabContent } from 'vue-form-wizard'

export default {
  components: {
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BCard,
    BFormTextarea,

    BCardHeader,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      applicationData: [],
      participants: [],
      selectOrg: Number.parseInt(this.$route.params.aid, 10),
      mutationLoading: false,
      status: 'disabled',
      title: '',
      userOrgs: getUserData()
        .associatedOrgDetails
        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  computed: {
    readonly() {
      return !this.$route.params.aid || this.status !== 'enabled' || this.$route.query.readonly === 'true'
    },
    questions() {
      this.applicationData.map(el => (el.programs_startupresponsetables.length === 0 ? el.programs_startupresponsetables.push({
        answer: '',
        participant_id: this.$route.params.aid,
      }) : el.programs_startupresponsetables))
      return this.groupByKey(this.applicationData, 'section')
    },
    answers() {
      const arr = []
      this.applicationData.map(r => arr.push({
        assignment_question_id: r.id,
        participant_id: this.$route.params.aid,
        ...r.programs_startupresponsetables[0],
      }))
      arr.map(x => Object.assign(x, { answer: JSON.stringify(x.answer) }))

      return arr
    },
  },
  methods: {
    groupByKey(array, key) {
      return Array.from(array)
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash
          return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
    },
    updateAnswer() {
      if (this.readonly) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Submissions are disabled.',
            text: 'If you think this is an error, please contact administrator.',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation ($answers: [programs_startupresponsetable_insert_input!]!) {
          insert_programs_startupresponsetable(objects: $answers, on_conflict: {constraint: programs_responsetable_pkey, update_columns: answer}) {
            affected_rows
          }
        }`,
        variables: {
          answers: this.answers,
        },
        update: (store, { data: { insert_programs_startupresponsetable } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_startupresponsetable.affected_rows ? 'Answers submitted successfully' : 'Failed to submit answers',
              icon: insert_programs_startupresponsetable.affected_rows ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_startupresponsetable.affected_rows ? 'success' : 'warning',
            },
          })
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    applicationData: {
      query() {
        return gql`
        {
            programs_assignmenttable_by_pk(id: ${this.$route.params.apid}) {
              id
              status
              title
              programs_assignmentquestionstables {
                id
                programs_startupresponsetables(where: {participant_id: {_eq: ${this.$route.params.aid || 0}}}) {
                    id
                    answer
                    participant_id
                }
                section
                question
                options
                input_type
              }
            }
        }`
      },
      update(data) {
        this.status = data.programs_assignmenttable_by_pk.status
        this.title = data.programs_assignmenttable_by_pk.title
        if (data.programs_assignmenttable_by_pk) {
          data.programs_assignmenttable_by_pk.programs_assignmentquestionstables.forEach(q => {
            q.programs_startupresponsetables.map(a => {
              try {
                JSON.parse(a.answer)
              } catch (e) {
                return a
              }
              return Object.assign(a, { answer: JSON.parse(a.answer) })
            })
          })
        }
        return data.programs_assignmenttable_by_pk.programs_assignmentquestionstables
      },
    },
    participants: {
      query() {
        return gql`query MyQuery($_in: [Int!] = [${this.userOrgs}]) {
          programs_startupparticipants(where: {programs_applicantstable: {programs_basicinfo: {programs_operationstables: {programs_assignmenttables: {id: {_eq: ${this.$route.params.apid}}}}}, organization_id_id: {_in: $_in}}}) {
            programs_applicantstable {
              users_associationtable {
                users_customuser {
                  full_name
                }
              }
            }
            id
          }
        }`
      },
      update(data) {
        if (!data.programs_startupparticipants.length) return []
        return data.programs_startupparticipants
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
